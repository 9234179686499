import { Button, DialogContentText, Stack, Typography } from "@mui/material";
import CodeBlock from "../../../Elements/CodeBlock";

const dbInfo = {
    postgres: {
        href: {
            main: "https://docs.amvera.ru/databases/postgreSQL.html",
            secondary: {
                name: "pgAdmin",
                linkTo: "https://docs.amvera.ru/databases/postgreSQL.html#pgadmin"
            }
        }
    },
    mysql: {
        href: {
            main: "https://docs.amvera.ru/databases/mysql-beta.html#id8"
        }
    }
};

export function DbInfoTab({ ownerName, slug, serviceType, db }) {
    return (
        <div className="postgres-details">
            <Stack spacing={2} direction="row">
                <Button
                    variant="outlined"
                    color="info"
                    startIcon={<i className="fa-solid fa-circle-info" />}
                    href={dbInfo[db].href.main}
                    target="_blank"
                    rel="noreferrer"
                >
                    Подключение к БД
                </Button>
                {dbInfo[db].href.secondary && (
                    <Button
                        variant="outlined"
                        color="info"
                        startIcon={<i className="fa-solid fa-circle-info" />}
                        href={dbInfo[db].href.secondary.linkTo}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Деплой {dbInfo[db].href.secondary.name}
                    </Button>
                )}
            </Stack>

            <div className="info-tab__block">
                <Typography variant="body1">
                    Подключение к БД возможно только из других проектов, развернутых в Amvera.
                </Typography>
                {serviceType === "cnpg" ? (
                    <div>
                        <DialogContentText>
                            Для доступа к базе данных из других проектов Амвера достаточно указать одно из трех доменных
                            имен:
                        </DialogContentText>
                        <ol className="info-tab__block_list">
                            <li>
                                <p>Для чтения/записи:</p>
                                <CodeBlock>
                                    amvera-{ownerName}-{serviceType}-{slug}-rw
                                </CodeBlock>
                            </li>
                            <li>
                                <p>Только для чтения в режиме hot standby:</p>
                                <CodeBlock>
                                    amvera-{ownerName}-{serviceType}-{slug}-ro
                                </CodeBlock>
                            </li>
                            <li>
                                <p>Для чтения с произвольной из развернутых нод:</p>
                                <CodeBlock>
                                    amvera-{ownerName}-{serviceType}-{slug}-r
                                </CodeBlock>
                            </li>
                        </ol>
                    </div>
                ) : (
                    <Stack>
                        <DialogContentText sx={{ marginBottom: "16px !important" }}>
                            Для доступа к базе данных из других проектов Амвера достаточно указать:
                        </DialogContentText>
                        <CodeBlock>
                            {ownerName}-{slug}-haproxy
                        </CodeBlock>
                    </Stack>
                )}
            </div>
        </div>
    );
}
