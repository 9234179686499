import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import React from "react";
import { AuthProvider } from "react-oidc-context";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import EntryPoint from "./Components/EntryPoint";
import EventProcessor from "./Components/EventProcessor";
import ConfigProvider from "./Configuration/ConfigProvider";
import { useConfig } from "./Configuration/hooks";
import ServiceProvider from "./DI/ServiceProvider";
import { registerServices } from "./Services";
import store from "./Store";
import LogSnackbar from "./Components/Elements/LogSnackbar";

const darkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 920,
            lg: 1200,
            xl: 1536
        }
    },
    palette: {
        mode: "dark",
        background: {
            default: "#39393A",
            paper: "#39393A",
            dark: "#333333"
        },
        primary: {
            main: "#BB4F11",
            light: "#BB4F11",
            dark: "#BB4F11",
            contrastText: "#E0E2DB"
        },
        secondary: {
            main: "#E05F15",
            light: "#E05F15",
            dark: "#E05F15",
            contrastText: "#E0E2DB",
            text: "#B7BBC1"
        },
        tertiary: {
            main: "#9575CD",
            contrastText: "#E0E2DB",
            code: "#656565"
        },
        text: {
            primary: "#E0E2DB",
            secondary: "#E0E2DB",
            disabled: "#E0E2DB",
            hint: "#E0E2DB"
        },
        action: {
            disabled: "#808080"
        }
    },
    typography: {
        fontFamily: `"Noto Sans", sans-serif`,
        button: {
            textTransform: "none"
        },
        code: {
            fontFamily: "Noto Sans Mono"
        }
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "&.Mui-disabled": {
                        "color": theme.palette.action.disabled,
                        "& .MuiSvgIcon-root": {
                            color: theme.palette.action.disabled
                        }
                    }
                })
            }
        }
    }
});

const ConfiguredApp = () => {
    const oidcConfig = useConfig().oidc;

    return (
        <AuthProvider {...oidcConfig}>
            <ServiceProvider factory={registerServices}>
                <EventProcessor>
                    <BrowserRouter>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={darkTheme}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <SnackbarProvider
                                        maxSnack={3}
                                        Components={{
                                            errorLog: LogSnackbar
                                        }}
                                    >
                                        <CssBaseline />
                                        <EntryPoint />
                                    </SnackbarProvider>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </BrowserRouter>
                </EventProcessor>
            </ServiceProvider>
        </AuthProvider>
    );
};

const App = () => (
    <ReduxProvider store={store}>
        <ConfigProvider>
            <ConfiguredApp />
        </ConfigProvider>
    </ReduxProvider>
);

export default App;
