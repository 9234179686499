import axios from "axios";

export default class FileService {
    constructor(auth, api_base) {
        this.auth = auth;
        this.api_base = api_base;
    }

    download = (
        token_endpoint,
        download_endpoint,
        selectedItems,
        accept = "application/json",
        content_type = "application/json"
    ) => {
        const default_headers = {
            "Authorization": `Bearer ${this.auth.user?.access_token}`,
            "Accept": accept,
            "Content-Type": content_type
        };
        fetch(this.api_base + token_endpoint, {
            method: "GET",
            headers: default_headers
        })
            .then(response => response.json())
            .then(data => {
                const selectedDirectories = Object.keys(selectedItems)
                    .filter(key => selectedItems[key])
                    .join(",");
                const link = document.createElement("a");
                link.href = `${this.api_base}${download_endpoint}?token=${
                    data.token
                }&directory=${selectedDirectories.toUpperCase()}`;

                document.body.appendChild(link); // 4. Force download
                link.click(); // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    };

    downloadFile = (
        token_endpoint,
        download_endpoint,
        selectedDir,
        filePath,
        accept = "application/json",
        content_type = "application/json"
    ) => {
        const default_headers = {
            "Authorization": `Bearer ${this.auth.user?.access_token}`,
            "Accept": accept,
            "Content-Type": content_type
        };

        fetch(this.api_base + token_endpoint, {
            method: "GET",
            headers: default_headers
        })
            .then(response => response.json())
            .then(data => {
                const link = document.createElement("a");
                link.href = `${this.api_base}${download_endpoint}?token=${data.token}&directory=${selectedDir}&filePath=${filePath}`;

                document.body.appendChild(link); // 4. Force download
                link.click(); // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    };

    upload = async (formData, upload_endpoint, setProgress) => {
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${this.auth.user?.access_token}`
        };
        try {
            const response = await axios.post(this.api_base + upload_endpoint, formData, {
                headers,
                onUploadProgress: progressEvent => {
                    const progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(progressPercent);
                }
            });
            setProgress(100); // Reset progress bar
            return response;
        } catch (error) {
            console.error("Error uploading files:", error);
            return null;
        }
    };

    applyConfig = (formData, project) => {
        const default_headers = {
            "Authorization": `Bearer ${this.auth.user?.access_token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const configuration = {
            meta: {
                environment: formData.meta.environment,
                toolchain: { name: formData.meta.toolchain, version: formData.meta.version }
            },
            build: {
                ...formData.build
            },
            run: {
                ...formData.run
            }
        };
        return fetch(`${this.api_base}/projects/${project.slug}/config?slug=${project.slug}`, {
            method: "POST",
            headers: default_headers,
            body: configuration != null ? JSON.stringify(configuration) : undefined
        });
    };
}
