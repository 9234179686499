import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { v4 as uuidV4 } from "uuid";

export const usePersistentSnackbar = () => {
    const { enqueueSnackbar } = useSnackbar();

    const removeNotification = useCallback(id => {
        let notifications = JSON.parse(localStorage.getItem("notifications")) || [];
        notifications = notifications.filter(notification => notification.id !== id);
        localStorage.setItem("notifications", JSON.stringify(notifications));
    }, []);

    const showNotification = useCallback(
        (id, contentInfo, serviceName, stage, date) => {
            enqueueSnackbar("", {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                autoHideDuration: 120_000,
                variant: "errorLog",
                key: id,
                onClose: (event, reason, key) => {
                    if (reason === "clickaway") return;
                    removeNotification(key);
                },
                contentInfo,
                serviceName,
                stage,
                date
            });
        },
        [enqueueSnackbar, removeNotification]
    );

    useEffect(() => {
        const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];

        storedNotifications.forEach(({ id, contentInfo, serviceName, stage, date }) => {
            showNotification(id, contentInfo, serviceName, stage, date);
        });
    }, [showNotification]);

    const addNotification = useCallback(
        (stage, serviceName, contentInfo) => {
            const notifications = JSON.parse(localStorage.getItem("notifications")) || [];
            const id = uuidV4();
            const date = new Date().toTimeString().split(" ")[0];
            const newNotification = { id, contentInfo, serviceName, stage, date };

            notifications.push(newNotification);

            if (notifications.length > 3) notifications.shift();
            localStorage.setItem("notifications", JSON.stringify(notifications));
            showNotification(id, contentInfo, serviceName, stage, date);
        },
        [showNotification]
    );

    return { addNotification };
};
