import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Delete } from "@mui/icons-material";
import { Checkbox, CircularProgress, IconButton, Link, Stack, Switch, TableCell, TableRow } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useServices } from "../../../../DI/hooks";
import usePut from "../../../../Hooks/usePut";
import { hasText } from "../../../../utils";
import { SNACKBAR_TYPE } from "../../../../constants";
import { DomainPortsTable } from "./DomainPortsTable";

export const Row = memo(({ row, defaultDomainActivated, setDefaultDialogOpen, onDelete, setDefaultDomainPorts }) => {
    const [open, setOpen] = useState(false);
    const [reqTLS, setReqTLS] = useState(row?.requireTLS);
    const { slug: slugName } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setReqTLS(row?.requireTLS);
    }, [row]);

    const { api } = useServices();

    const executeAddPorts = useCallback(
        (projectSlug, id, domain) => api.domain.update(projectSlug, id, domain),
        [api.domain]
    );
    const [loadingTLS, handleSwitchTLS] = usePut(executeAddPorts, true);

    const handleCellClick = useCallback(
        e => {
            const value = e.target.textContent;
            if (!hasText(value)) return;
            navigator.clipboard.writeText(value).then(() => enqueueSnackbar("Скопировано в буфер", SNACKBAR_TYPE.INFO));
        },
        [enqueueSnackbar]
    );

    const handleChangeTLS = useCallback(
        e => {
            const TLS = e.target.checked;

            handleSwitchTLS(
                [slugName, row.id, { active: row?.activated, requireTLS: TLS, ingressPorts: row?.ingressPorts }],
                `TLS успешно ${TLS ? "включен" : "выключен"}.`
            );

            setReqTLS(TLS);
        },
        [row, slugName, handleSwitchTLS]
    );

    return (
        <>
            <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell onClick={handleCellClick} sx={{ cursor: "pointer" }}>
                    <Link
                        href={`https://${row.domainName}`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ color: "#fff", textDecoration: "none" }}
                        onClick={e => e.stopPropagation()}
                    >
                        {row.domainName ?? "-"}
                    </Link>
                </TableCell>
                <TableCell align="center">
                    {row.isDefault ? (
                        <Switch
                            checked={defaultDomainActivated || false}
                            onChange={() => {
                                setDefaultDialogOpen(prev => !prev);
                            }}
                        />
                    ) : (
                        <Delete onClick={() => onDelete(row)} />
                    )}
                </TableCell>

                <TableCell align="center" width={52} height={42}>
                    <Stack alignItems="center" justifyContent="center" width={42} height={42}>
                        {loadingTLS ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Checkbox defaultChecked={reqTLS} checked={reqTLS} onChange={handleChangeTLS} />
                        )}
                    </Stack>
                </TableCell>

                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <DomainPortsTable
                        domainInfo={row}
                        ports={row.ingressPorts}
                        open={open}
                        setDefaultDomainPorts={setDefaultDomainPorts}
                    />
                </TableCell>
            </TableRow>
        </>
    );
});
