import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    FormHelperText,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useServices } from "../../../../DI/hooks";
import usePut from "../../../../Hooks/usePut";
import { DeletePortButton } from "./DeletePortButton";

export function DomainPortsTable({ domainInfo, ports, open, setDefaultDomainPorts }) {
    const [currentPorts, setCurrentPorts] = useState(ports);
    const { api } = useServices();
    const { slug: slugName } = useParams();
    const executeAddPorts = useCallback(
        (projectSlug, id, domain) => api.domain.update(projectSlug, id, domain),
        [api.domain]
    );
    const [loadingPorts, handleAddPorts] = usePut(executeAddPorts, true);
    const [loadingRemovePort, handleRemoveCurrentPort] = usePut(executeAddPorts, true);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, errors }
    } = useForm({
        defaultValues: { ports: [{ urlPath: "", port: null }] }
    });

    const { fields, append, remove } = useFieldArray({ control, name: "ports" });

    const fieldArrayLastIndex = useMemo(() => fields.length - 1, [fields]);

    const handleAddNewRow = useCallback(() => {
        append({ urlPath: "", port: null });
    }, [append]);

    const handleAddNewPorts = useCallback(
        async data => {
            if (data.ports[fieldArrayLastIndex]?.urlPath === "" && data.ports[fieldArrayLastIndex]?.port === null) {
                data.ports.pop();
            }

            if (data.ports) {
                const changedDomain = await handleAddPorts([
                    slugName,
                    domainInfo.id,
                    {
                        active: domainInfo.activated,
                        requireTLS: domainInfo.requireTLS,
                        ingressPorts: [...currentPorts, ...data.ports]
                    }
                ]);

                setCurrentPorts([...changedDomain.ingressPorts]);
                if (domainInfo.isDefault) setDefaultDomainPorts([...changedDomain.ingressPorts]);

                reset();
            }
        },
        [reset, currentPorts, domainInfo, slugName, handleAddPorts, fieldArrayLastIndex, setDefaultDomainPorts]
    );
    const handleRemovePort = useCallback(index => remove(index), [remove]);

    const generateFields = useMemo(
        () => (
            <>
                {fields.map((field, index) => (
                    <TableRow key={field.id} sx={{ "& > *": { border: 0 } }}>
                        <TableCell style={{ verticalAlign: "top" }}>
                            <Controller
                                rules={{
                                    pattern: {
                                        value: /^\/.*/,
                                        message: "Путь должен начинаться с /."
                                    },
                                    required: (fieldArrayLastIndex !== index || index === 0) && "Укажите путь URL."
                                }}
                                control={control}
                                name={`ports.${index}.urlPath`}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        placeholder="Введите URL-путь"
                                        inputProps={{ sx: { fontSize: 14 } }}
                                        {...field}
                                    />
                                )}
                            />

                            {errors.ports?.[index]?.urlPath && (
                                <FormHelperText error>{errors.ports?.[index]?.urlPath?.message}</FormHelperText>
                            )}
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                            <Controller
                                rules={{
                                    valueAsNumber: true,
                                    required: (fieldArrayLastIndex !== index || index === 0) && "Укажите порт."
                                }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="number"
                                        variant="standard"
                                        size="small"
                                        defaultValue={undefined}
                                        placeholder="Введите порт"
                                        onChange={e => field.onChange(parseFloat(e.target.value))}
                                        inputProps={{ sx: { fontSize: 14 } }}
                                    />
                                )}
                                name={`ports.${index}.port`}
                            />

                            {errors.ports?.[index]?.port && (
                                <FormHelperText error>{errors.ports?.[index]?.port?.message}</FormHelperText>
                            )}
                        </TableCell>
                        <TableCell sx={{ width: 72, border: 0 }}>
                            {fieldArrayLastIndex !== index && (
                                <IconButton onClick={() => handleRemovePort(index)}>
                                    <DeleteOutlineOutlinedIcon color="primary" fontSize="small" />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </>
        ),
        [control, errors.ports, fieldArrayLastIndex, fields, handleRemovePort]
    );

    const handleRemoveExistingPort = useCallback(
        async domainId => {
            const filteredPorts = currentPorts.filter(({ id }) => id !== domainId);

            await handleRemoveCurrentPort(
                [
                    slugName,
                    domainInfo.id,
                    {
                        active: domainInfo.activated,
                        requireTLS: domainInfo.requireTLS,
                        ingressPorts: filteredPorts
                    }
                ],
                "Успешно удалено."
            );

            setCurrentPorts(filteredPorts);
            if (domainInfo.isDefault) setDefaultDomainPorts(filteredPorts);
        },
        [
            handleRemoveCurrentPort,
            currentPorts,
            domainInfo.activated,
            domainInfo.id,
            domainInfo.requireTLS,
            domainInfo.isDefault,
            setDefaultDomainPorts,
            slugName
        ]
    );

    return (
        <>
            <Collapse in={open} unmountOnExit>
                <Box sx={{ width: "100%", overflow: "hidden", padding: "16px 0px" }}>
                    <form onSubmit={handleSubmit(handleAddNewPorts)}>
                        <Table size="small" sx={{ maxWidth: 800, marginLeft: "auto" }}>
                            <TableBody>
                                {currentPorts.map(portInfo => (
                                    <TableRow key={portInfo.id}>
                                        <TableCell>{portInfo.urlPath}</TableCell>
                                        <TableCell>{portInfo.port}</TableCell>
                                        <TableCell>
                                            <Stack alignItems="center" justifyContent="center" width={42} height={42}>
                                                {!loadingRemovePort ? (
                                                    <DeletePortButton
                                                        removePort={() => handleRemoveExistingPort(portInfo.id)}
                                                    />
                                                ) : (
                                                    <CircularProgress size={20} />
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {domainInfo.id !== 0 ? (
                                    generateFields
                                ) : (
                                    <Typography textAlign="center" height={40}>
                                        Необходимо изначально активировать домен.
                                    </Typography>
                                )}
                            </TableBody>
                        </Table>
                        <Stack alignItems="center" direction="row" justifyContent="end">
                            <Button onClick={handleAddNewRow} disabled={domainInfo.id === 0}>
                                Добавить
                            </Button>
                            <LoadingButton type="submit" loading={loadingPorts} disabled={!isDirty}>
                                Применить
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Collapse>
        </>
    );
}
