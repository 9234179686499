import Endpoint from "./Endpoint";

export default class FileStorageEndpoint extends Endpoint {
    download(projectSlug) {
        return this.http.get(`${this.address}/${projectSlug}/userdata/download`);
    }

    upload(projectSlug) {
        return this.http.get(`${this.address}/${projectSlug}/userdata/upload`);
    }
}
