import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import DefaultDomainDialog from "../DefaultDomainDialog";
import { useServices } from "../../../DI/hooks";
import { Row } from "./ui";

const DomainTable = ({ domains, onDelete, project, fetchDomains, handleApiError }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [defaultDomainPorts, setDefaultDomainPorts] = useState([]);

    const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
    const [defaultDomain, setDefaultDomain] = useState(null);
    const [activeButton, setActiveButton] = useState(true);

    const { api } = useServices();

    // seariching for default domain object
    useEffect(() => {
        setDefaultDomain(domains.filter(d => d.isDefault)[0] || null);
    }, [domains]);

    useEffect(() => {
        setDefaultDomain(prev => ({ ...prev, ingressPorts: [...defaultDomainPorts] }));
    }, [defaultDomainPorts]);

    const handleDefaultDomainPorts = useCallback(ports => {
        setDefaultDomainPorts(ports);
    }, []);

    const closeDefaultDalog = useCallback(() => {
        setDefaultDialogOpen(false);
    }, []);

    const defaultDomainAction = useCallback(() => {
        setActiveButton(false);

        const action =
            defaultDomain.activated || defaultDomain.id !== 0
                ? api.domain.update(project.slug, defaultDomain.id, {
                      active: !defaultDomain.activated,
                      requireTLS: true,
                      ingressPorts: defaultDomain.ingressPorts
                  })
                : api.domain.activateDefault(project.slug, defaultDomain.ingressPorts);

        action
            .then(response => (response.ok ? fetchDomains() : Promise.reject(response)))
            .catch(e => {
                handleApiError(e);
            })
            .finally(() => {
                closeDefaultDalog();
                setActiveButton(true);
            });
    }, [api.domain, closeDefaultDalog, fetchDomains, handleApiError, project.slug, defaultDomain]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <DefaultDomainDialog
                open={defaultDialogOpen}
                onClose={() => setDefaultDialogOpen(false)}
                onSave={defaultDomainAction}
                active={defaultDomain?.activated || false}
                activeButton={activeButton}
            />
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table size="small" sx={{ minWidth: 500 }}>
                        <TableHead>
                            <TableRow sx={{ height: 56 }}>
                                <TableCell align="center">Название</TableCell>
                                <TableCell align="center">Действия</TableCell>
                                <TableCell align="center">TLS</TableCell>
                                <TableCell sx={{ width: 10 }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {domains.map(row => (
                                <Row
                                    key={row.id}
                                    row={row}
                                    defaultDomainActivated={defaultDomain?.activated}
                                    setDefaultDialogOpen={setDefaultDialogOpen}
                                    onDelete={onDelete}
                                    setDefaultDomainPorts={handleDefaultDomainPorts}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={domains?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default DomainTable;
