import { useEffect } from "react";
import { useEventContext } from "../../../Context/EventContext";
import { usePersistentSnackbar } from "../../../Hooks/usePersistentSnackbar";

// const notifications = [
//     {
//         username: "user1",
//         stage: "BUILD",
//         serviceName: "test3",
//         content:
//             "Log 1 ERROR afdfda f dafasdfsad dsa fhasdf hsdjahf jadhf jsda dsa fasdh fjhsdah fsad fd fhadjsh sadh fhsadj hfjsa fjasd f"
//     },
//     { username: "user1", stage: "RUN", serviceName: "test2", content: "Log 2 ERROR" },
//     { username: "user1", stage: "BUILD", serviceName: "test3", content: "Log 3 ERROR" },
//     { username: "user1", stage: "RUN", serviceName: "test3", content: "Log 4 ERROR" },
//     { username: "user1", stage: "NONE", serviceName: "test3", content: "Log 5 ERROR" },
//     { username: "user1", stage: "RELEASE", serviceName: "test3", content: "Log 6 ERROR" }
// ];

const LogSnackbarHandler = () => {
    const userLogsErrors = useEventContext();
    const { addNotification } = usePersistentSnackbar();

    // console.log(userLogsErrors);
    // const [notificationId, setNotificationId] = useState(0);

    useEffect(() => {
        if (userLogsErrors) {
            const { username, stage, serviceName, content } = userLogsErrors;
            addNotification(stage, serviceName, content);
        }
        // addNotification(
        //     "BUILD",
        //     "test3",
        //     "[33mWARN[0m[0001] Failed to retrieve image node:18-alpine from remapped registry mirror.gcr.io: unable to complete operation after 0 attempts, last error: GET https://mirror.gcr.io/v2/token?scope=repository%3Alibrary%2Fnode%3Apull&service=: UNAUTHORIZED: authentication failed. Will try with the next registry, or fallback to the original re"
        // );
        // const show = () => {
        //     const { stage, serviceName, content, ...other } = notifications[notificationId];
        //     console.log(notificationId);
        //     addNotification(content, serviceName, stage);
        //     setNotificationId(prev => prev + 1);
        // };
        // const intervalId = setInterval(show, 15000);
        // return () => clearInterval(intervalId);
    }, [userLogsErrors, addNotification]);

    return null;
};

export default LogSnackbarHandler;
