import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDomainName } from "../../../Store/DomainSlice";
import { hasText } from "../../../utils";
import { useServices } from "../../../DI/hooks";

const DomainDialog = ({ open, onSave, onClose }) => {
    const dispatch = useDispatch();

    const { config } = useServices();

    const currentProject = useSelector(state => state.currentProject);

    const { project } = currentProject;

    const domain = useSelector(state => state?.domain ?? null);

    const handleChangeDomainName = useCallback(
        ({ currentTarget }) => dispatch(setDomainName(currentTarget?.value ?? "")),
        [dispatch]
    );

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Добавление доменного имени </DialogTitle>
            <DialogContent dividers>
                <DialogContentText pb={2}> Для добавления домена Вам необходимо: </DialogContentText>
                <DialogContentText pb={1}>
                    {`1) Создать в DNS A запись: ${config.ipv4} и TXT запись: ${project.ownerName}-${project.slug}`}
                </DialogContentText>
                <DialogContentText pb={1}>
                    2) Дождаться, пока обновления распространятся по DNS серверам
                </DialogContentText>
                <DialogContentText pb={1}>3) Ввести доменное имя в поле ниже (без http или https)</DialogContentText>
                <TextField
                    required
                    fullWidth
                    type="text"
                    label="Доменное имя (без http или https)"
                    value={domain?.domainName ?? ""}
                    onChange={handleChangeDomainName}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>
                <Button variant="contained" disabled={!hasText(domain?.domainName)} onClick={onSave}>
                    Подтвердить и привязать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DomainDialog;
